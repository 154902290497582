<template>
    <div class="d-player_container"  >
      <div class='d-player_video_main' ref='videoMain'>
        <video  
          ref="player" 
          class='d-player_video  video-js vjs-big-play-centered vjs-fluid'
          x5-video-player-type="h5-page"
          preload="metadata"
          playsinline="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-video-orientation="portraint"
          x5-video-player-fullscreen=""
        >
          <source  :src="url" :type="type" />
        </video>
      </div>
      <!-- <div class='d-player_video_controls_cover' ref='controlsMain'  @click.stop='toggleControl' v-if='false'>
        <div class="d-player_video_controls_playbutton" v-show='showControl'>
          <i :class='`el-icon-video-${getStatus() ? "play" : "pause"}`' v-show='getStatus()'  @click.stop="play"></i>
        </div>
        <div class='d-player_video_controls' v-show='showControl'>
          <div class='d-player_video_controls_main'>
            <div class="d-player_video_pause d-player_video_controls_button" @click.stop='toggle'>
              <i :class='`el-icon-video-${getStatus() ? "play" : "pause"}`'></i>
            </div>
            <div class="d-player_video_fullqueen d-player_video_controls_button"  @click.stop='fullScreen'>
              <i class='el-icon-full-screen'></i>
            </div>
          </div>
        </div>
      </div> -->
      <Cover ref="cover" :isLive="isLive" :phase="phase" :chatList="chatList" :url="cover" :isShowButton="isShowButton" v-if="showCover" @click="coverPlay()"></Cover>
    </div>
</template>
<script>
import Cover from '@/components/unit/Cover'
export default {
  name:'d-player',
  props:{
    phase:{
      type:Number,
      default:0
    },
    chatList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    clickCoverPlay:{
      type:Boolean,
      default:false
    },
    isShowCover:{
      type:Boolean,
      default:false
    },
    isLive:{
      type:Boolean,
      default:false
    },
    isShowButton:{
      type:Boolean,
      default:false
    },
  },
  data:()=>{
    return {
      cover:"",
      showCover:false,
      url:'',
      demoUrl:"https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/statics/demo.mp4",
      index:0,
      type:"application/x-mpegURL",
      player:null,
      showControl:false,
      isFullscreen:false,
      timer:null,
      isPlayEnd:false,
      options:{
        controls: false,//用户可以与之交互的控件
        // loop:true,//视频一结束就重新开始
        predload:'metadata',
        muted:false,//默认情况下将使所有音频静音
        // aspectRatio:"16:9",//显示比率
        sources: [
            {
              src:'',
              type: 'application/x-mpegURL'
            }
        ]
      },
      currTime:0,
      isFirst:true
    }
  },
  components:{
    Cover
  },
  mounted(){
    this.showCover = !!this.$props.isShowCover
    this.switch("https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/statics/demo.mp4",'video/mp4')
  },
  beforeDestroy() {
      if (this.player) {
          this.player.dispose()
      }
  },
  methods:{
    getStatus(){
      return this.player ? this.player.paused() : false;
    },
    coverPlay(){
      if(this.clickCoverPlay||(this.url != this.demoUrl && !this.isPlayEnd)){
        this.play();
      }
    },
    play(status){
      this.$nextTick(()=>{
        this.player.play();
        // this.player.currentTime(20)
        this.showCover = false
        this.showControl = false;
        this.$emit("beginVideo")
      })
    },
    playDefault(){
      this.$nextTick(()=>{
        this.player.play();
        this.showCover = true
      })

    },
    pause(){
      this.player.pause();
    },
    toggleControl(){
      this.showControl = !this.showControl;
    },
    setControl(status){
      this.showControl = status;
    },
    toggle(){
      let key = this.getStatus() ? 'play' : 'pause';
      this[key]();
      this.index++;
    },
    fullScreen(){
      this.isFullscreen = this.player.isFullscreen();
      if(!this.isFullscreen){
        this.player.requestFullscreen()
      }else{
        this.player.exitFullscreen();
      }
    },
    setCoverStatus(status){
      this.showCover = status
    },
    setCover(cover){
      this.cover = cover
      this.showCover = true
      this.$nextTick(()=>{
        this.$refs.cover.setURL(cover)
      })
    },
    switch(url,currTime=0,type="video/mp4"){
      if(!url) return;
      this.$nextTick(()=>{
        this.url = url;
        this.currTime = currTime;
        this.type = type;
        this.options.sources[0].src = url;
        this.options.sources[0].type = type;
        let that = this;
        this.isPlayEnd = false;
        if(this.player){
          // console.log(url,type,4444)
          this.player.on('error', ()=>{
            this.player.load(url)
          })
          this.player.options = this.options;
          this.player.src(url);  //重置video的src
          this.player.load(url);  //使video重新加载
          // this.play()
          return;
        }
        this.player = this.$video(this.$refs.player,this.options,function onPlayerReady(){
          this.pause()
          this.on('fullscreenchange', that.fullscreenchange)
          this.on('timeupdate', that.timeupdate)
          this.on('ended', that.playEnd)
          this.on('loadedmetadata',function(){
            if(that.isFirst) {
              that.isFirst = false
              return
            };
            that.loaded()
          })
        })
      })
    },
    timeupdate(){
      this.$emit("updateTime",this.player.currentTime())
    },
    seek(time){
      this.player.currentTime(time)
    },
    playEnd(){
      this.isPlayEnd = true;
      this.showCover = true
      this.$emit("playEnd")
    },
    fullscreenchange(e){
      this.isFullscreen = this.player.isFullscreen();
      this.showControl = this.isFullscreen;
      // this.$refs.videoMain.children[0].appendChild(this.$refs.controlsMain)
      if(!this.$tools.isAndroid()){
        setTimeout(()=>{
          this.play()
        },1000)
      }
    },
    setMuted(status){
      this.player && this.player.setMuted(status)
    },
    loaded(){
      if(this.currTime){
        this.seek(this.currTime)
      }
      // this.play(true)
      this.$emit('loaded');
    },
  }
}
</script>
<style>
/* 时间进度条 */
.d-player_container,.d-player_video{
  width:100vw;
  height:100vw;
  overflow: hidden;
  position: relative;
}
.d-player_container .cover-container{
  height:100vw;
  width:100vw;
}
.video-js.vjs-fluid:not(.vjs-audio-only-mode){
  width:100vw;
  height:100vw;
}
.d-player_container video::-webkit-media-controls-timeline {
    display: none !important;
}
/* 全屏按钮 */
.d-player_container video::-webkit-media-controls-fullscreen-button {
    display:none !important;
    content: "\e719";
}
/* 音量按钮 */
.d-player_container video::-webkit-media-controls-mute-button {
    display: none!important;            
}
.d-player_video_main{
  position: relative;
  z-index:5;
  height:100vw;
  width:100vw;
}
.d-player_video_main video{
  height:100vw !important;
  width:100vw !important;
  position: absolute !important;
  left:0;
  top:0 !important;
}
.d-player_video_controls_cover{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:transparent;
  z-index:1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.d-player_video_controls_playbutton{
    width: 100%;
    flex:1 0 0;
    font-size: 24vw;
    color: white;
    text-shadow:-3px 0 black,0 3px black,3px 0 black,0 -3px black;
    display: flex;
    align-items: center;
    justify-content:center;
}
.d-player_video_controls{
  flex:8vw 0 0 ;
  width:100%;
  z-index:10;
}
.d-player_video_controls_main{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height:100%;
  background-image:linear-gradient(0deg, #000, #fff);
}
.d-player_video_controls_button{
  font-size:4vw;
  color:#fff;
  width:8vw;
  height:100%;
  display: flex;
  align-items: center;
  justify-content:center;

}
.video-js.vjs-fluid{
  height:100vw !important
}
.vjs-control-bar{
  opacity: 1;
  position: absolute;
  /* bottom:12px  !important; */
  background-image:linear-gradient(0deg, #000, #fff);
}
.vjs-volume-panel,.vjs-live-control,.vjs-picture-in-picture-control{
  display:none !important;
}
.vjs-fullscreen-control{
  position: absolute  !important;
  right:0;
  display:none !important;
}
.vjs-button>.vjs-icon-placeholder:before{
  display: inline-block;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  font-size:4vw;
}
.vjs-big-play-button{
  display: none !important;
}
</style>